<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="-1"
      hide-default-footer
    >
      <template #item="{item}">
        <tr>
          <td>{{ item.place }}</td>
          <td>{{ item.club }}</td>
          <td>{{ item.athletes }}</td>
          <td v-for="d in d" :key="d._discipline">
            {{ item[d._discipline] | float2 }}
          </td>
          <td style="font-size:120%;font-weight:bold;">{{ item.final | float2_0 }}</td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { useCalc } from '@/views/components/stb_ltf_2024/plugins/calc'

export default {
  name: 'zwischenstand',

  setup () {
    return {
      ...useCalc()
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  computed: {
    d () {
      return this.e.disciplines?.slice()?.sort((a, b) => a.order < b.order ? -1 : 1)?.map(d => ({
        ...d,
        ...this.df.find(d2 => d2._id === d._discipline)
      })) || []
    },
    headers () {
      return [
        { text: 'Platz', value: 'place', sortable: false },
        { text: 'Verein', value: 'club', sortable: false },
        { text: 'Turner', value: 'athletes', sortable: false },
        ...this.d.map(d => ({ text: d.shortName, value: d._id, sortable: false })),
        { text: 'Gesamt', value: 'final', sortable: false }
      ]
    },
    items () {
      return this.e.teams?.map(t => ({
        club: t.club.name,
        athletes: t.athletes.map(a => Vue.filter('person')(a)).join('; '),
        ...this.d.reduce((acc, curr) => {
          acc[curr._discipline] = this.mannschaftgeraetergebnis(this.e, this.r, curr._discipline, this.teamid(t))
          return acc
        }, {}),
        final: this.mannschaftergebnis(this.e, this.r, this.teamid(t))
      })).sort((a, b) => a.final > b.final ? -1 : 1).map((t, _, arr) => ({
        ...t,
        place: arr.filter(t2 => t2.final > t.final).length + 1
      }))
    }
  }
}
</script>

<style scoped>

</style>
